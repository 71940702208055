<div class="dashboard-card dash_cont" style="position: relative">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="getLotName() !== ''">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em> -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                    <li (click)="configModal.showModal()"><span>{{"widget.changeView" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div
        *ngIf="isLoading"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
        "
    >
        <h3 style="text-align: center">
            <span class="loader">
                <i></i>
            </span>
        </h3>
    </div>
    <div class="adjustable_div">
        <devices-list 
            [devices]="devices" 
            [deviceColumns]="deviceColumns"
            [showHeader]="false" 
            [bgColor]="bgColor" 
            [profiles]="profiles"
            [sensorGroupTypes]="sensorGroupTypes" 
            [sensorGroupTypeId]="sensorGroupTypeId"
            (refreshSensorGroupTypes)="refreshSensorGroupTypes.emit($event)"
            (currentView)="currentView.emit($event)"
            (setTriggerSensorGroupTypes)="setTriggerSensorGroupTypes.emit($event)"
            [triggerSensorGroupTypes]="triggerSensorGroupTypes"
        ></devices-list>
    </div>

    <fap-modal
    [inputIsLarge]="true"
    [showFooter]="false"
    #configModal
    [titleColor]="'#ffffff'"
    inputTitle="Config"
    (outputOnHide)="reroute()"
>
    <div class="config_block">
        <form [formGroup]="sensorForm" class="row">
            <div class="col-md-12">
                <fap-add-edit-delete-select 
                    [inputName]="'sensor.sensorGroup' | translate"
                    class="w-100"
                    [formControl]="sensorForm.controls['sensorGroup']"
                    name="group"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="sensorGroupTypes"
                    [inputConfirmDeleteText]="'sensor.deleteSensorGroupMessage' | translate"
                    (selectionChange)="changeGroup($event)"
                    (outputOnCreate)="showSensorGroupTypeModal(); closeCombo = false;"
                    (outputOnEdit)="sensorGroupTypeEdit($event); closeCombo = false;"
                    (outputOnDelete)="sensorGroupTypeDelete($event);"
                    [closeCombo]="closeCombo"
                    >
                </fap-add-edit-delete-select>
            </div>
            <mat-form-field class="col-md-5 col-sm-4">
                <mat-label>{{ "sensor.selectColumns" | translate }}</mat-label>
                <mat-select #matSelect required formControlName="sensorIds" (selectionChange)="onOptionSelected($event)">
                  <mat-select-trigger>
                    {{ 'Select items' }}
                  </mat-select-trigger>
                  <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                    <input matInput #farmFilter placeholder="Search" />
                  </mat-form-field>
                  <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <div class="slct">
                      <mat-option
                        *ngFor="let column of allDeviceColumns | filter : farmFilter.value"
                        [value]="column?.sensor_ref?.id"
                        [ngClass]="{'highlighted': column.highlighted}"
                        style="text-transform: capitalize"
                        (click)="$event.preventDefault();"
                      >
                      <span class="middle_text d-flex no_bg align-items-center">
                            <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                            <div class="device_name">
                                <span>{{ column?.name }}</span>
                                <span class="small_text" style="display: block;">
                                    {{ column?.sensor_ref?.unit_type?.unit }}
                                </span>
                            </div>
                        </span>
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
          
            <div class="btns-group">
                <button class="btn" (click)="selectAllItems()"><img src="../../../../../../assets/images/select_all.png" alt=""></button>
                <button class="btn" (click)="removeAllItems()"><img src="../../../../../../assets/images/remove_all.png" alt=""></button>
            </div>
          </form>                   

        <div class="form-group">
            <mat-list (dragover)="allowDrop($event)" (drop)="onDrop($event)">
                <mat-list-item
                    *ngFor="let column of deviceColumns; let idx = index"
                    style="text-transform: capitalize"
                    draggable="true"
                    (dragstart)="onDragStart($event, idx)"
                >
                <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                <span class="middle_text d-flex align-items-center">
                    {{column?.name}}
                  <span class="small_text pt-0" style="display: block; padding-left: 5px;">{{ column?.sensor_ref?.unit_type?.unit }}</span>
                    </span>
                    <span class="mr-1 ml-auto" style="text-align: right; display: flex">
                        <em class="material-icons pt-1 theme-color" (click)="removeItem(column?.sensor_ref?.id)">delete</em>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="btn-group d-flex justify-content-end">
            <button class="btn mr-2" (click)="addColumns()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="configModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</fap-modal>

<fap-modal #addEditSensorGroupTypeModal
    [inputPrimaryButtonInterface]="addEditSensorGroupTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addEditSensorGroupTypeModalButtonSecondaryInterface"
    [inputTitle]="'sensor.addEditSensorGroupType' | translate">
    <form [formGroup]="sensorGroupTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="sensorGroupTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="sensorGroupTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.sensorGroupType)" 
        [prefix]="'SENSOR_GROUP_'" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>