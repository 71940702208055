import { NgModule } from '@angular/core';
import {
    CommonModule,
    NgSwitch
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

import { MapHelper } from '../core/heplers/map.helper';
import { NavService } from './services/nav.service';
import { ConfirmModalService } from './services/confirm-modal.service.ts/confirm-modal.service';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

// import {
//     ToggleFullscreenDirective
// } from './directives';

import {
    FooterComponent,
    FapHeaderComponent,
    LoaderComponent,
    FapSidebarComponent,
    FapMainMapComponent,
    MapMenuComponent
} from './layout';

import {
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FapModalComponent,
    FapUploadImageComponent,
    FapWeatherIconSvgComponent
} from './partials';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FapUploadAvatarComponent } from './partials/components/upload/avatar/fap-upload-avatar.component';
import { FapGeneralWeatherWidgetComponent } from './widgets/fap_general-weather-widged/fap_general-weather-widged.component';
import { FapWeatherWidgetComponent } from './widgets/fap_weather-widget/fap_weather-widget.component';
import { FapSoilWidgetComponent } from './widgets/fap_soil-widget/fap_soil-widget.component';
import { FapSingleChartsBarVerticalComponent } from './widgets/fap_single-charts-bar-vertical/fap_single-charts-bar-vertical.component';
import { FapHorizontalLineChartComponent } from './widgets/fap_line-chart/fap_line-chart.component';
import { FapPieChartGridComponent } from './widgets/fap_pie-chart-grid/fap_pie-chart-grid.component';
import { FapSingelPieChartComponent } from './widgets/fap_single-pie-chart/fap_single-pie-chart.component';
import { FapAdvancedPieChartComponent } from './widgets/fap_advanced-pie-chart/fap_advanced-pie-chart.component';
import { FapHorizontalBarLineChartComponent } from './widgets/fap_horizontal-bar-chart/fap_horizontal-bar-chart.component';
import { FapVerticalBarChartComponent } from './widgets/fap_vertical-bar-chart/fap_vertical-bar-chart.component';
import { FapListWidgetComponent } from './widgets/fap_list-widget/fap_list-widget.component';
import { FapAlertListComponent } from './widgets/fap_alert-list/fap_alert-list.component';
import { FapAlertListRowComponent } from './widgets/fap_alert-list/fap_alert-list-row/fap_alert-list-row.component';
import { FapActivityListComponent } from './widgets/fap_activity-list/fap_activity-list.component';
import { FapActivityListRowComponent } from './widgets/fap_activity-list/fap_activity-list-row/fap_activity-list-row.component';
import { FapActivityListGroupRowComponent } from './widgets/fap_activity-list/fap_activity-list-group-row/fap_activity-list-group-row.component';
import { FapResourcesTableComponent } from './partials/components/fap_resources-table/fap_resources-table.component';
import { FapWizardReviewDirective } from './layout/fap_wizard/fap_base/fap-wizard-review.directive';
import { FapWizardContentDirective } from './layout/fap_wizard/fap_base/fap-wizard-content.directive';
import { FapWizard1Component } from './layout/fap_wizard/fap_base/fap_wizard1/fap-wizard1.component';
import { FapAddWidgetWizardComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_add-widget-wizard.component';
import { FapSelectWidgetInfoTypeComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_select-widget-info-type/fap_select-widget-info-type.component';
import { FapSelectWidgetComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_select-widget/fap_select-widget.component';
import { FapWidgetWrapperComponent } from './widgets/fap_widget-wrapper/fap_widget-wrapper.component';
import { FapConfigureWidgetComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_configure-widget/fap_configure-widget.component';
import { FapWizardBaseDirective } from './layout/fap_wizard/fap_base/fap-wizard-base.component';
import { FapAddEditActivityComponent } from './partials/components/fap_add-edit-activity/fap_add-edit-activity.component';
import { FapConfirmModalComponent } from './partials/components/fap-confirm-modal/fap-confirm-modal.component';
import { FapStockFarmWidgetComponent } from './widgets/fap_stock-farm-widget/fap_stock-farm-widget.component';
import { FapAddEditDeleteComponent } from './partials/components/fap_add-edit-delete-select/fap_add-edit-delete-select.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { RoundButtonComponent } from './partials/components/fap-round-button/round-button.component';
import { ProgressBarComponent } from './partials/components/progress-bar/progress-bar.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FabMulitImgUploadComponent } from './partials/components/upload/fab-mulit-img-upload/fab-mulit-img-upload.component';
import { FapTableWidgetComponent } from './widgets/fap-table-widget/fap-table-widget.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { FapChartWidgetComponent } from './widgets/fap-chart-widget/fap-chart-widget.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UnitPipe } from '../core/pipes/uint.pipe';
import { FapCropEyeWidgetComponent } from './widgets/fap-crop-eye-widget/fap-crop-eye-widget.component';
import { FapImageSliderWidgetComponent } from './widgets/fap-image-slider-widget/fap-image-slider-widget.component';
import { FapImageWidgetComponent } from './widgets/fap-image-widget/fap-image-widget.component';
import { FapOverviewWidgetComponent } from './widgets/fap-overview-widget/fap-overview-widget.component';
import { MinMaxPipe } from '../core/pipes/minMax.pipe';
import { FapWaterContentWidgetComponent } from './widgets/fap-water-content-widget/fap-water-content-widget.component';
import { FapCropEyeSimpleWidgetComponent } from './widgets/fap-crop-eye-simple-widget/fap-crop-eye-simple-widget.component';
import { FapSatelliteWidgetComponent } from './widgets/fap-satellite-widget/fap-satellite-widget.component';
import { TimeZonePipe } from '../core/pipes/timeZone.pipe';
import { FapAlertWidgetComponent } from './widgets/fap-alert-widget/fap-alert-widget.component';
import { FapWeedControlWidgetComponent } from './widgets/fap-weed-control-widget/fap-weed-control-widget.component';
import { TimerPipe } from '../core/pipes/timer.pipe';
import { getLastTransmissionPipe } from '../core/pipes/deviceTransmission.pipe';
import { getLotNamesPipe } from '../core/pipes/getLotNames.pipe';
import { FapCarouselWidgetComponent } from './widgets/fap-carousel-widget/fap-carousel-widget.component';
import { FapBinWidgetComponent } from './widgets/fap-bin-widget/fap-bin-widget.component';
import { FapDeviceInfoWidgetComponent } from './widgets/fap-device-info-widget/fap-device-info-widget.component';
import { AddEditTranslationsComponent } from '../views/views-partials/fap_view-components/add-edit-translations/add-edit-translations.component';
import { ChipsComponent } from './partials/components/chips/chips.component';
import { FapWeatherForecastWidgetComponent } from './widgets/fap-weather-forecast-widget/fap-weather-forecast-widget.component';
import { getUnitPipe } from '../core/pipes/unit.pipe';
import { StepperComponent } from '../views/views-partials/fap_view-components/stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FapSensorValueWidgetComponent } from './widgets/fap-sensor-value-widget/fap-sensor-value-widget.component';
import { FapActivitiesListComponent } from './widgets/activities-list-widget/activities-list-widget.component';
import { TimeAgoPipe } from '../core/pipes/timeAgo.pipe';
import { RandomPipe } from '../core/pipes/randomNumber.pipe';
import { PoolWidgetComponent } from './widgets/pool-widget/pool-widget.component';
import { CircleProgressBarComponent } from './partials/components/circle-progress-bar/circle-progress-bar.component';
import { FapAttributesComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-attributes.component';
import { FapAttributeFormsComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-attribute-forms/fap-attribute-forms.component';
import { FapClassicalFormComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-classical-form/fap-classical-form.component';
import { ClassicalFormFieldComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-classical-form/classical-form-field/classical-form-field.component';
import { DragCarouselComponent } from './partials/components/drag-carousel/drag-carousel.component';
import { CameraComponent } from '../views/views-partials/fap_view-components/camera/camera.component';
import { MapWidgetComponent } from './widgets/map-widget/map-widget.component';
import { FapListAttributeRelationsComponent } from '../views/views-partials/fap_view-components/fap-list-attribute-relations/fap-list-attribute-relations.component';
import { FapAttributesMapComponent } from '../views/views-partials/fap_view-components/fap-attributes-map/fap-attributes-map.component';
import { ScrollService } from './services/scroll.service';
import { getProductUnitPipe } from '../core/pipes/productUnit.pipe';
import { UtcToLocalPipe } from '../core/pipes/utcToLocal.pipe';
import { GraphRangeWidgetComponent } from './widgets/graph-range-widget/graph-range-widget.component';
import { GraphPercentageWidgetComponent } from './widgets/graph-percentage-widget/graph-percentage-widget.component';
import { CellCostQtyComponent } from './partials/components/cell-cost-qty/cell-cost-qty.component';
import { ItemsTableComponent } from './partials/components/items-table/items-table.component';
import { TranslationPipe } from '../core/pipes/translation.pipe';
import { ImageComponent } from './partials/components/image/image.component';
import { ProductTagComponent } from './partials/components/product-tag/product-tag.component';
import { ProductsModalComponent } from './partials/components/products-modal/products-modal.component';
import { FapAddEditProductComponent } from '../views/views-partials/fap_view-components/fap-add-edit-product/fap-add-edit-product.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FapUnitValueComponent } from '../views/views-partials/fap_view-components/fap_unit-value/fap_unit-value.component';
import { FapAddEditPartyComponent } from '../views/views-partials/fap_view-components/fap_add-edit-party/fap_add-edit-party.component';
import { FapAddEditResourceComponent } from '../views/views-partials/fap_view-components/fap_add-edit-resource/fap_add-edit-resource.component';
import { FapAddEditGroupComponent } from '../views/views-partials/fap_view-components/fap_add-edit-group/fap_add-edit-group.component';
import { FapAddEditResourceTypeComponent } from '../views/views-partials/fap_view-components/fap_add-edit-resource-type/fap_add-edit-resource-type.component';
import { FapAddEditIngredientTypeComponent } from '../views/views-partials/fap_view-components/fap_add-edit-ingredient-type/fap_add-edit-ingredient-type.component';
import { FapAddEditIngredientComponent } from '../views/views-partials/fap_view-components/fap_add-edit-ingredient/fap_add-edit-ingredient.component';
import { FapAddEditUnitComponent } from '../views/views-partials/fap_view-components/fap_add-edit-unit/fap_add-edit-unit.component';
import { FapAddEditAddressComponent } from '../views/views-partials/fap_view-components/fap_add-edit-address/fap-add-edit-address.component';
import { ActionButtonsComponent } from './partials/components/action-buttons/action-buttons.component';
import { FapIngredientTableComponent } from '../views/views-partials/fap_view-components/fap_ingredient-table/fap_ingredient-table.component';
import { ImageListComponent } from './partials/components/image-list/image-list.component';
import { ImageAspectRatioClassPipe } from '../core/pipes/imageResolution.pipe';
import { ImageComboComponent } from './partials/components/image-combo/image-combo.component';
import { FapEquipmentComboComponent } from './partials/components/fap-equipment-combo/fap-equipment-combo.component';
import { FapFarmSelectionComponent } from './partials/components/header-components/fap-farm-selection/fap-farm-selection.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { FapSearchFilterComponent } from './partials/components/header-components/fap-search-filter/fap-search-filter.component';
import { FapHeaderActionsComponent } from './partials/components/header-components/fap-header-actions/fap-header-actions.component';
import { FapTreeComboComponent } from './partials/components/fap-tree-combo/fap-tree-combo.component';
import { FapSectionHeaderComponent } from './partials/components/fap-sections/fap-section-header/fap-section-header.component';
import { FapSectionBodyComponent } from './partials/components/fap-sections/fap-section-body/fap-section-body.component';
import { FapSectionContainerComponent } from './partials/components/fap-sections/fap-section-container/fap-section-container.component';
import { FapWeatherForecastComponent } from './partials/components/overview-components/fap-weather-forecast/fap-weather-forecast.component';
import { FapOverviewImageComponent } from './partials/components/overview-components/fap-overview-image/fap-overview-image.component';
import { FapSchedulerTriggerComponent } from './partials/components/scheduler-components/fap-scheduler-trigger/fap-scheduler-trigger.component';
import { FapSchedulerAutomationComponent } from './partials/components/scheduler-components/fap-scheduler-automation/fap-scheduler-automation.component';
import { FapAddEditSchedulerComponent } from './partials/components/scheduler-components/fap-add-edit-scheduler/fap-add-edit-scheduler.component';
import { FapSchedulerRowComponent } from './partials/components/scheduler-components/fap-scheduler-row/fap-scheduler-row.component';
import { DocumentsModalComponent } from './partials/components/documents-modal/documents-modal.component';
import { WorkflowModalComponent } from './partials/components/workflow-modal/workflow-modal.component';
import { CriticalOperationComponent } from './partials/components/critical-operation/critical-operation.component';
import { TableSortDirective } from './directives/tableSort.directive';
import { PaginationComponent } from './partials/components/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DynamicDatePipe } from '../core/pipes/dynamicDate.pipe';
import { MasonryDirective } from './directives/masonry.directive';
import { getUnitTypePipe } from '../core/pipes/getUnitType.pipe';
import { FapAddEditTypeComponent } from './partials/components/fap-add-edit-type/fap-add-edit-type.component';
import { TreatmentsTableComponent } from '../views/pages/treatments/treatments-table/treatments-table.component';
import { ProductsLabelModalComponent } from './partials/components/products-label-modal/products-label-modal.component';
import { FapAddEditProductLabelComponent } from '../views/views-partials/fap_view-components/fap-add-edit-product-label/fap-add-edit-product-label.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FapInfoSectionsComponent } from './partials/components/fap-info-sections/fap-info-sections.component';
import { FapAddEditInfoSectionComponent } from './partials/components/fap-info-sections/fap-add-edit-info-section/fap-add-edit-info-section.component';
import { FapInfoSectionComponent } from './partials/components/fap-info-sections/fap-info-section/fap-info-section.component';
import { NgxEditorModule } from 'ngx-editor';
import { AddEditBodyTranslationsComponent } from '../views/views-partials/fap_view-components/add-edit-body-translations/add-edit-body-translations.component';
import { FapDevicesWidgetComponent } from './widgets/fap-devices-widget/fap-devices-widget.component';
import { FapDevicesTableComponent } from './partials/components/fap-devices-table/fap-devices-table.component';
import { DevicesListComponent } from '../views/pages/devices/components/devices-list/devices-list.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableModule } from '../views/views-partials/fap_view-components/resizable/resizable.module';


const FAP_PARTIALS: any[] = [
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FapUploadImageComponent,
    FapModalComponent,
    FapResourcesTableComponent,
    FapUploadAvatarComponent,
    FapWeatherIconSvgComponent,
    FapWizard1Component,
    FapAddWidgetWizardComponent,
    FapSelectWidgetInfoTypeComponent,
    FapSelectWidgetComponent,
    FapConfigureWidgetComponent,
    FapWidgetWrapperComponent,
    FapWizardBaseDirective,
    FapActivityListRowComponent,
    FapActivityListGroupRowComponent,
    FapAddEditActivityComponent,
    FapAlertListRowComponent,
    FapConfirmModalComponent,
    FapStockFarmWidgetComponent,
    FapAddEditDeleteComponent,
    FabMulitImgUploadComponent,
    AddEditTranslationsComponent,
    AddEditBodyTranslationsComponent,
    ChipsComponent,
    StepperComponent,
    CircleProgressBarComponent,
    FapAttributesComponent,
    FapAttributeFormsComponent,
    FapClassicalFormComponent,
    ClassicalFormFieldComponent,
    FapListAttributeRelationsComponent,
    FapAttributesMapComponent,
    DragCarouselComponent,
    CameraComponent,
    ProgressBarComponent,
    CellCostQtyComponent,
    ItemsTableComponent,
    ImageComponent,
    ProductTagComponent,
    ProductsModalComponent,
    FapAddEditProductComponent,
    FapIngredientTableComponent,
    FapUnitValueComponent,
    FapAddEditPartyComponent,
    FapAddEditResourceComponent,
    FapAddEditGroupComponent,
    FapAddEditResourceTypeComponent,
    FapAddEditIngredientTypeComponent,
    FapAddEditIngredientComponent,
    FapAddEditUnitComponent,
    FapAddEditAddressComponent,
    RoundButtonComponent,
    ActionButtonsComponent,
    ImageListComponent,
    ImageComboComponent,
    RoundButtonComponent,
    ProgressBarComponent,
    FapEquipmentComboComponent,
    FapFarmSelectionComponent,
    FapSearchFilterComponent,
    FapHeaderComponent,
    FapHeaderActionsComponent,
    FapTreeComboComponent,
    FapSectionContainerComponent,
    FapSectionHeaderComponent,
    FapSectionBodyComponent,
    FapWeatherForecastComponent,
    FapOverviewImageComponent,
    FapSchedulerTriggerComponent,
    FapSchedulerAutomationComponent,
    FapAddEditSchedulerComponent,
    FapSchedulerRowComponent,
    DocumentsModalComponent,
    WorkflowModalComponent,
    CriticalOperationComponent,
    PaginationComponent,
    TreatmentsTableComponent,
    FapAddEditProductLabelComponent,
    FapAddEditTypeComponent,
    ProductsLabelModalComponent,
    FapInfoSectionsComponent,
    FapAddEditInfoSectionComponent,
    FapInfoSectionComponent,
    DevicesListComponent,
    FapDevicesTableComponent
];

const FAP_LAYOUT: any[] = [
    LoaderComponent,
    FapSidebarComponent,
    FapMainMapComponent,
    MapMenuComponent,
];

const FAP_DIRECTIVES: any[] = [
    // ToggleFullscreenDirective,
    FapWizardReviewDirective,
    FapWizardContentDirective,
    TableSortDirective,
    MasonryDirective
];

const FAP_WIDGETS: any[] = [
    FapGeneralWeatherWidgetComponent,
    FapWeatherWidgetComponent,
    FapSoilWidgetComponent,
    FapSingleChartsBarVerticalComponent,
    FapHorizontalLineChartComponent,
    FapPieChartGridComponent,
    FapSingelPieChartComponent,
    FapAdvancedPieChartComponent,
    FapHorizontalBarLineChartComponent,
    FapVerticalBarChartComponent,
    FapListWidgetComponent,
    FapActivityListComponent,
    FapAlertListComponent,
    FapTableWidgetComponent,
    FapChartWidgetComponent,
    FapCropEyeWidgetComponent,
    FapImageSliderWidgetComponent,
    FapImageWidgetComponent,
    FapOverviewWidgetComponent,
    FapWaterContentWidgetComponent,
    FapSatelliteWidgetComponent,
    FapCropEyeSimpleWidgetComponent,
    FapAlertWidgetComponent,
    FapWeedControlWidgetComponent,
    FapCarouselWidgetComponent,
    FapBinWidgetComponent,
    FapDeviceInfoWidgetComponent,
    FapWeatherForecastWidgetComponent,
    FapSensorValueWidgetComponent,
    FapActivitiesListComponent,
    PoolWidgetComponent,
    MapWidgetComponent,
    GraphRangeWidgetComponent,
    GraphPercentageWidgetComponent,
    FapDevicesWidgetComponent
];

const FAP_PIPES: any[] = [
    FilterPipe,
    UnitPipe,
    MinMaxPipe,
    TimeZonePipe,
    TimerPipe,
    getLastTransmissionPipe,
    getLotNamesPipe,
    getUnitPipe,
    TimeAgoPipe,
    RandomPipe,
    getProductUnitPipe,
    UtcToLocalPipe,
    TranslationPipe,
    ImageAspectRatioClassPipe,
    DynamicDatePipe,
    getUnitTypePipe
];
@NgModule({
    declarations: [
        ...FAP_LAYOUT,
        ...FAP_PARTIALS,
        ...FAP_DIRECTIVES,
        ...FAP_WIDGETS,
        ...FAP_PIPES
    ],
    exports: [
        ...FAP_LAYOUT,
        ...FAP_PARTIALS,
        ...FAP_DIRECTIVES,
        ...FAP_WIDGETS,
        ...FAP_PIPES,
        TranslateModule,
    ],
    imports: [
        CommonModule,
        NgApexchartsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        InfiniteScrollModule,
        MatInputModule,
        MatSelectModule,
        MatNativeDateModule,
        MatDialogModule,
        MatTableModule,
        MatAutocompleteModule,
        NgxCsvParserModule,
        MatTreeModule,
        MatPaginatorModule,
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
        NgxSpinnerModule,
        MatCheckboxModule,
        MatStepperModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatProgressBarModule,
        NgxEditorModule,
        MatListModule,
        MatExpansionModule,
        DragDropModule,
        ResizableModule
    ],
    providers: [
        NavService,
        TranslateService,
        NgSwitch,
        ConfirmModalService,
        ScrollService
    ]
})
export class SharedModule {}
