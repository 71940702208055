import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel, ObjectService } from '../../../core/services/api/objects/object.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../../partials';
import { TypesService } from '../../../core/services/api/types/types.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FapModalButtonInterface } from '../../partials/components/fap-modal/data/fap-modal-button.interface';

@Component({
  selector: 'fap-devices-widget',
  templateUrl: './fap-devices-widget.component.html',
  styleUrls: ['./fap-devices-widget.component.scss']
})
export class FapDevicesWidgetComponent implements OnInit, OnChanges {

  @Input() public devices = [];
  @Input() public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() activities: any[] = [];
  @Input() sensorGroupTypes: any[] = [];
  @Input() persons: any[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() public langString: string;
  @Input() public bgColor: string;
  @Input() public triggerSensorGroupTypes: boolean = false;
  public isLoading = false;

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public refreshSensorGroupTypes: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public setTriggerSensorGroupTypes: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public currentView: EventEmitter<any> = new EventEmitter<any>();

  public toggleMain = false;
  public sensorForm: UntypedFormGroup;
  public sensorGroupTypeId: any = null;
  public deviceColumns = [];
  public deviceData = [];
  public itemList = [];
  @Input() public profiles = [];
  draggedItemIndex: number;
  public getMoreData = true;
  public sensorGroupType: any;
  @ViewChild('configModal') configModal: FapModalComponent;
  public deviceNextToken: { limit: number; offset: number; search: string; lot: string, group: string } = null;
  public label:string;
  public closeCombo: boolean = true;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  translation = null;
  public sensorGroupTypeForm: UntypedFormGroup;
  @Input() public allDeviceColumns = [];
  @ViewChild('addEditSensorGroupTypeModal')
  public addEditSensorGroupTypeModal: FapModalComponent;
  @ViewChild('mys') mys;
  public addEditSensorGroupTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addEditSensorGroupTypeModalButtonSecondaryInterface: FapModalButtonInterface;

  constructor(public globalRegistry: GlobalRegistryService, private fb: FormBuilder, private objectService: ObjectService, private cacheService: CacheResolverService, private typeService: TypesService, private translateService: TranslateService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.sensorForm = this.fb.group({
      sensorIds: [[], Validators.required],
      sensorGroup: [this.sensorGroupTypeId, Validators.required]
    });
    this.initSensorGroupTypeForm();
    // this.getDeviceDataColumns(this.sensorGroupTypeId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('widget') && this.widget) {
      if(this.widget.settings && this.widget.settings['sensorGroupview']) {
        this.sensorGroupTypeId = this.widget.settings['sensorGroupview'];
        this.getDeviceDataColumns(this.sensorGroupTypeId);
      }
    }
  }

  selectAllItems() {
    this.deviceColumns = this.allDeviceColumns;
    this.deviceColumns.forEach(column => {
      this.itemList.push({sensor_ref: column.sensor_ref.id});
    });
  }
  
  removeAllItems() {
    this.deviceColumns = [];
    this.itemList = [];
    // this.sensorForm.reset();
  }

  onDragStart(event: DragEvent, index: number) {
    this.draggedItemIndex = index;
  }
  
  allowDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  onDrop(event: DragEvent) {
    event.preventDefault();
    const targetIndex = this.findIndexFromEvent(event);
  
    if (targetIndex !== -1 && this.draggedItemIndex !== targetIndex) {
      this.moveItem(this.deviceColumns, this.draggedItemIndex, targetIndex);
      this.moveItem(this.itemList, this.draggedItemIndex, targetIndex);
    }
  
    this.draggedItemIndex = undefined;
  }
  
  findIndexFromEvent(event: DragEvent): number {
    const targetElement = event.target as HTMLElement;
    const dropTarget = targetElement.closest('[draggable="true"]');
    return dropTarget ? Array.from(dropTarget.parentElement.children).indexOf(dropTarget) : -1;
  }
  
  moveItem(array: any[], fromIndex: number, toIndex: number) {
    const [removedItem] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removedItem);
  }

  changeGroup(group) {
    this.sensorForm.get('sensorGroup').setValue(group);
    this.getDeviceDataColumns(group);
  }

  sensorGroupTypeEdit(sensorGroup){
    this.sensorGroupTypeId = sensorGroup.id;
    this.sensorGroupType = sensorGroup;
    console.log(this.sensorGroupType)
    this.sensorGroupTypeForm.controls['name'].setValue(sensorGroup.name);
    this.sensorGroupTypeForm.controls['name_t'].setValue(sensorGroup.nameT);
    this.sensorGroupTypeForm.controls['color'].setValue(sensorGroup.color);
    this.showSensorGroupTypeModal(sensorGroup);
}

  initSensorGroupTypeForm() {
    this.sensorGroupTypeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      name_t: new UntypedFormControl(''),
      color: new UntypedFormControl('#ff0000'),
    });
  }

  sensorGroupTypeDelete(sensorGroupType){
    console.log(sensorGroupType);
    if(sensorGroupType.id == this.sensorGroupTypeId) {
      this.sensorGroupType = null;
      this.sensorGroupTypeId = null;
    }
    this.typeService.deleteSensorGroupType(sensorGroupType.id).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translateService.instant('sensor.deleteSensorGroupType'))
      this.refreshSensorGroupTypes.emit();
    })
  }

  public showSensorGroupTypeModal(sensorGroup?:any): void {
    if(sensorGroup) {
        this.sensorGroupType = sensorGroup
        this.sensorGroupTypeId = sensorGroup.id;
    } else {
      this.sensorGroupType = null
      this.sensorGroupTypeId = null;
      this.initSensorGroupTypeForm();
    }
    this.addEditSensorGroupTypeModal.showModal();
  }

  public initAddsensorGroupTypeModalButtons(): void {
    const _this: FapDevicesWidgetComponent = this;

    this.addEditSensorGroupTypeModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.sensorGroupTypeSubmit();
        },
        text: this.translateService.instant('save')
    };

    this.addEditSensorGroupTypeModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addEditSensorGroupTypeModal.hideModal();
        },
        text: this.translateService.instant('cancel')
    };
  }

  public sensorGroupTypeSubmit(){
    if(this.sensorGroupTypeForm.invalid) {
        Object.keys(this.sensorGroupTypeForm.controls).forEach((controlName: string) =>
            this.sensorGroupTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    if(this.sensorGroupTypeId){
        this.editSensorGroupType(this.sensorGroupTypeForm.value, this.sensorGroupTypeId);
        this.addEditSensorGroupTypeModal.hideModal();
        
    } else {
        this.addSensorGroupType(this.sensorGroupTypeForm.value)
        this.addEditSensorGroupTypeModal.hideModal();
        
    }
  }

  addColumns() {
    console.log(this.sensorForm.value);
    this.sensorGroupTypeId = this.sensorForm.get('sensorGroup').value;
    this.objectService.postColumns(this.itemList, this.sensorGroupTypeId ? this.sensorGroupTypeId : '').subscribe(data => {
      if(data) {
        console.log(data);
        this.configModal.hideModal();
        this.deviceColumns = data.body.results;
        this.currentView.emit({sensorGroupTypeId: this.sensorForm.get('sensorGroup').value, widget: this.widget});
        this.getDeviceData(this.sensorGroupTypeId);
      }
    })
  }

  getDeviceData(group?:any) {
    this.isLoading = true;
    this.deviceData = [];
    const params = {
      limit: 20,
      offset: 0,
      search: '',
      lot : '',
      group: group ? group : ''
    }
    const url = this.objectService.getUrl('adv_data/');
    // if(this.searchString) { Object.assign(params, {search : this.searchString}) }
    this.objectService.getAdvDeviceData(params).subscribe(data => {
      this.deviceData = data.body.results;
      this.isLoading = false;
      this.cacheService.delete(url+'limit=20&offset=0&search='+params.search+'&lot='+params.lot+'&group='+params.group);
      if(this.deviceData) {
        if(data.body.next == null) {
          this.getMoreData = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          this.deviceNextToken = {limit: params['limit'], offset: params['offset'], search: params['search'] ? params['search'] : '', lot: params['lot'] ? params['lot'] : '', group: params['group']};
        }
      }
    })
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.sensorGroupTypeForm.controls['name_t'].setValue(translation.id);
    this.sensorGroupTypeForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  addEditTranslation(label) {
    this.label = label;
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.sensorGroupType);

    if (name === '' || type.nameT === null) {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
        return;
    }

    for (const translation of this.globalRegistry.systemData.translations) {
        if (type && translation.id === type.nameT) {
            this.translation = translation;
            console.log(this.translation);
            this.addEditTranslationsModal.showModal();
            return;
        }
    }

    if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.sensorGroupType.controls['name_t'].value;
        for (const translation of this.globalRegistry.systemData.translations) {
            if (translation.id === newTranslation) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
                return;
            }
        }
    }
  }

  public removeItem(sensor_ref_id: number): void {
    // const sensorIdsArray = this.sensorForm.get('sensorIds').value;
    // const index = sensorIdsArray.indexOf(sensor_ref_id);
  
    // if (index !== -1) {
    //   this.sensorForm.get('sensorIds').setValue(sensorIdsArray.filter(id => id !== sensor_ref_id));
    // }
  
    this.itemList = this.itemList.filter(item => item.sensor_ref !== sensor_ref_id);
  
    this.deviceColumns = this.deviceColumns.filter(column => column?.sensor_ref?.id !== sensor_ref_id);
  }

  getDeviceDataColumns(group = '') {
    group = group ? group: '';
    this.itemList = [];
    const url = this.objectService.getUrl('adv_data/columns/')
    this.objectService.getAdvDeviceDataColumns({group: group}).subscribe(data => {
      this.cacheService.delete(url+'group='+group);
      this.deviceColumns = data.body.results;
      const sensorIds = [];
      this.deviceColumns.forEach(column => {
        this.itemList.push({sensor_ref: column.sensor_ref.id});
        sensorIds.push(column.sensor_ref.id)
      });
      // this.sensorForm.get('sensorIds').setValue(sensorIds);
    })
  }

  public addSensorGroupType(sensorGroup): void {
    this.typeService.addSensorGroupType(sensorGroup).subscribe((data) => {
      if(data) {
        this.sensorGroupType = data.body.results;
        this.sensorGroupTypeId = this.sensorGroupType.id;
        this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
      }
        this.toastr.success(this.translateService.instant('sensor.addSensorGroupType'));
        this.refreshSensorGroupTypes.emit();
        this.closeCombo = true;
    });    
  }

  public editSensorGroupType(sensorGroup, sensorGroupTypeId: number): void {
          this.typeService.updateSensorGroupTypes(sensorGroupTypeId, sensorGroup).subscribe((data) => {
          if(data) {
            this.sensorGroupType = data.body.results;
            this.sensorGroupTypeId = this.sensorGroupType.id;
            const index = this.sensorGroupTypes.findIndex(type => type.id === sensorGroupTypeId);
            if (index !== -1) {
              this.sensorGroupTypes[index] = data.body.results;
            }
            this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
          }
          this.toastr.success(this.translateService.instant('sensor.updateSensorGroupType'));
          this.refreshSensorGroupTypes.emit();
          this.closeCombo = true;
      });    
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  onOptionSelected(event) {
    const ref = { sensor_ref: event.value };

    if (!this.isDuplicateItem(ref, this.itemList)) {
      this.itemList.push(ref);
    }

    const selectedColumn = this.allDeviceColumns.find(column => column?.sensor_ref?.id === ref.sensor_ref);

    if (selectedColumn && !this.isDuplicateColumn(selectedColumn, this.deviceColumns)) {
      this.deviceColumns.push(selectedColumn);
    }
}

isDuplicateItem(newItem, itemList) {
  return itemList.some(existingItem =>
    existingItem.sensor_ref === newItem.sensor_ref
  );
}

isDuplicateColumn(newColumn, deviceColumns) {
  return deviceColumns.some(existingColumn =>
    existingColumn?.sensor_ref?.id === newColumn?.sensor_ref?.id
  );
} 

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
    return lot ? lot.name : '';
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  reroute() {
    console.log("Ok");
  }
}
